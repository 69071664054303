import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {injectIntl, Link} from "gatsby-plugin-react-intl";
import ImageFluid from "./image-fluid";


function SimpleBlock({ intl, content }) {

    if (content.orientation === "left"){
        return(
            <div className={`simple-block ` + content.orientation}>
                <Row>
                    <Col xs={{ order: 1, span: 12  }} md={{ order: 1, span: 6 }}>
                        <ImageFluid alt={content.title} filename={content.image} />
                    </Col>
                    <Col xs={{ order: 12, span: 12  }} md={{ order: 12, span: 6 }}>
                        <div className={`content`}>
                            {content.date &&
                            <div className={`date`}>{content.date}</div>
                            }
                            {content.title &&
                            <div className={`title`}>{content.title}</div>
                            }
                            {content.subtitle &&
                            <div className={`subtitle`}>{content.subtitle}</div>
                            }
                            {content.text &&
                            <div className={`text`} dangerouslySetInnerHTML={{__html: content.text}}></div>
                            }
                            {content.button &&
                            <Link className={`btn btn-lg btn-secondary button`} to={content.button.url}>
                                {content.button.label}
                            </Link>
                            }
                        </div>
                    </Col>
                </Row>
            </div>

        )
    } else {
        return(
            <div className={`simple-block ` + content.orientation}>
                <Row>
                    <Col xs={{ order: 12, span: 12  }} md={{ order: 1, span: 6 }}>
                        <div className={`content`}>
                            {content.date &&
                            <div className={`date`}>{content.date}</div>
                            }
                            {content.title &&
                            <div className={`title`}>{content.title}</div>
                            }
                            {content.subtitle &&
                            <div className={`subtitle`}>{content.subtitle}</div>
                            }
                            {content.text &&
                            <div className={`text`} dangerouslySetInnerHTML={{__html: content.text}}></div>
                            }
                            {content.button &&
                            <Link className={`btn btn-lg btn-secondary button`} to={content.button.url}>
                                {content.button.label}
                            </Link>
                            }
                        </div>
                    </Col>
                    <Col xs={{ order: 1, span: 12  }} md={{ order: 12, span: 6 }}>
                        <ImageFluid alt={content.title} filename={content.image} />
                    </Col>
                </Row>
            </div>

        )
    }

}

export default injectIntl(SimpleBlock)

