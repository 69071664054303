import React from 'react';
import {Container} from 'react-bootstrap';
import {injectIntl} from "gatsby-plugin-react-intl";
import SimpleCTA from "../../components/ctas/simple-cta";
import SimpleBlock from "../misc/simple-block";
import ProductHighlight from "./product-highlight"

function FeaturesList({ intl, features, intro }) {

    return(

        <div className={`features-list`}>

            {intro &&
            <div className={`features-intro`}>
                <Container>
                    <div className={`content`}>
                        <div className={`title`}>{intro.title}</div>
                        <div className={`text`} dangerouslySetInnerHTML={{__html: intro.text}}></div>
                    </div>
                </Container>
            </div>
            }

            <div>

                {features.map((feature, i) => {

                    if (feature.cta) {

                        if (feature.type === "simple-cta") {
                            return (
                              <Container key={i}>
                                <SimpleCTA theme={feature.theme} content={feature} />
                              </Container>
                            )
                        } else {
                            return null;
                        }

                    } else if (feature.video) {

                      return (
                        <ProductHighlight key={i} theme={feature.theme} content={feature} />
                      )

                    } else {
                        return(
                          <Container key={i}>
                            <SimpleBlock content={feature} />
                          </Container>
                        )
                    }

                })}
            </div>
        </div>

    )

}

export default injectIntl(FeaturesList)
