import React from 'react';
import {injectIntl} from "gatsby-plugin-react-intl";
import {Container} from 'react-bootstrap';

function ProductHighlight({ intl, theme, content }) {

    const allow = () => {
      return content.allow || `accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture`;
    }
    return(

        <div className={`product-highlight bg-${theme}`}>

            <Container>
              <div className={`video-wrapper`}>
                  {content.videoId &&
                    <iframe
                      allowFullScreen={true}
                      allowscriptaccess={`always`}
                      allow={allow()}
                      frameBorder={0}
                      id={`videoFrame`}
                      src={`https://www.youtube-nocookie.com/embed/${content.videoId}?rel=0&modestbranding=1`}
                      title={`Product Highlight`}
                    ></iframe>
                  }
              </div>
            </Container>

        </div>

    )

}

export default injectIntl(ProductHighlight)
